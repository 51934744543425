<template>
    <a-row :gutter="[48,24]" class="search-form">
        <a-col :sm="16" :xxl="6">
            <a-form-item :label="$t('search')" :colon="false">
                <a-input-search allowClear v-model:value="formState.search" @search="formState.searchHandle"  :placeholder="$t('log.desc')" />
            </a-form-item>
        </a-col>
        <a-col v-if="ifAdmin" :sm="8" :xxl="6">
            <a-form-item  :label="$t('log.acct')" :colon="false">
                <a-select
                        ref="select"
                        allowClear
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        :options="formState.acctData"
                        :filter-option="false"
                        showSearch
                        @search="searchUser"
                        v-model:value="formState.uid"
                        @change="formState.searchHandle"/>
            </a-form-item>
        </a-col>
        <a-col :sm="16" :xxl="ifAdmin?8:14">
            <a-form-item :label="$t('date')" :colon="false">
                <a-range-picker style="width: 220px;" @change="formState.searchHandle" v-model:value="formState.date" />
            </a-form-item>
        </a-col>
        <a-col :sm="8" :xxl="4">
            <a-form-item :wrapper-col="{offset:8,span:16}" :colon="false" style="text-align: right;">
                <a-button @click="formState.reset">{{$t('reset')}}</a-button>
            </a-form-item>
        </a-col>
    </a-row>
</template>

<script>
    import {reactive, toRaw, computed, onMounted} from 'vue'
    import {useStore} from "vuex";
    export default {
        setup(){
            let store = useStore()
            let formState=reactive({
                search:null,
                uid:null,
                acctData:computed(()=>{
                    return store.state.user.data.map(r=>{
                        r.label=r.attr
                        r.value=r.id
                        return r
                    })
                }),
                date:[],
                searchHandle(){
                    store.dispatch('log/search',{searchForm:toRaw(formState),current:1,search:true})
                },
                reset(){
                    formState.search=null
                    formState.acct=null
                    formState.date=[]
                    store.dispatch('log/search',{searchForm:toRaw(formState),current:1,search:true,})
                },
            })
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)
            onMounted(()=>{
                if(ifAdmin.value){
                    store.dispatch('user/search',{searchForm:{search:''},current:1,search:true})
                }
                formState.searchHandle()
            })
            return {
                ifAdmin,
                formState,
                searchUser(e){
                    store.dispatch('user/search',{searchForm:{search:e},current:1,search:true})
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-form{
    ::v-deep(.ant-form-item){
        margin-bottom: 0;
    }
}
</style>