<template>
    <div class="list-view">
        <a-row :gutter="[72,12]">
            <a-col :span="24" class="search-class">
                <slot name="search-view"></slot>
            </a-col>
            <a-col :span="24" class="page-view">
                <slot name="page-view"/>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
    .list-view{
        margin:0 36px;
    }
    .search-class, .page-view {
        background: #ffffff;
        border-radius: 4px;
        padding-top: 36px;
        padding-bottom: 36px;
    }

</style>