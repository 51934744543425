<template>
    <div class="page">
        <div class="flex between" style="margin-bottom: 24px;">
            <h4>{{$t('log.title')}}</h4>
            <div style="text-align: right;">

            </div>
        </div>
        <a-table rowKey="id" :scroll="{ x:1000, y: 400 }" :columns="columns" :data-source="data" :pagination="pagination" />
    </div>
</template>

<script>
    import i18n from "../../../locale/main";
    import {reactive,computed} from 'vue'
    import { useStore } from 'vuex';
    export default {
        setup() {
            let t = i18n.global.t
            let store = useStore()

            const columns = [
                {
                    title: t('user.acct'),
                    width: 300,
                    dataIndex: 'acct',
                    ellipsis:true,
                },
                {title: t('user.attr'),dataIndex: 'attr',ellipsis:true,},
                {title: t('log.desc'),  dataIndex: 'desc',ellipsis:true,},
                {title: t('log.actTime'), dataIndex: 'actTime',ellipsis:true,},
                {title: t('ip'),  dataIndex: 'ip',ellipsis:true,},
            ].map((r,k) => {
                r.key = k;
                return r
            });


            const pagination=reactive({
                current:computed(()=>store.state.log.current),
                pageSize:computed(()=>store.state.log.size),
                pageSizeOptions:store.state.log.sizeAll,
                total:computed(()=>store.state.log.total),
                showSizeChanger:true,
                showQuickJumper:true,
                showTotal:(total)=>t('pages.total',{num:total}),
                async onChange(val){
                    await store.dispatch('log/search',{
                        current:val,
                        size:store.state.log.size,
                    })
                },
                async onShowSizeChange(_,size){
                    await store.dispatch('log/search',{
                        current:1,
                        size:size,
                    })
                },
            })
            return {
                columns,
                pagination,
                data: computed(()=>store.state.log.data)
            }
        }
    }
</script>

<style scoped>

</style>